a {
  cursor: pointer;
}
a[disabled] {
  color: rgb(204, 204, 204) !important;
  background-color: transparent !important;
  cursor: not-allowed !important;
}
a[disabled]:active {
  pointer-events: none !important;
}

.nk-fmg-aside {
  z-index: 601;
}

@media (min-width: 992px) {
  .nk-fmg-aside {
    left: 0px !important;
  }
  .nk-sidebar-overlay {
    display: none !important;
  }
}

@media (max-width: 992px) {
  .nk-fmg-aside {
    width: 270px;
    transition: 0.3s;
  }
}

.active.nk-fmg-menu-item {
  background: #eff1ff;
  border-radius: 3px;
}
.active.nk-fmg-menu-item .icon {
  color: #6576ff;
}
.active.nk-fmg-menu-item>.nk-fmg-menu-text {
  color: #6576ff;
}

.modal-md {
  margin: 0 !important;
}

.loading-app .content{
  width: 370px;height: 170px;margin: 0px auto;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);
}
.loading-app .logo {
  display: flex;
  max-height: 82px;
}
.loading-app .logo img{
  max-width: 240px;
  object-fit: contain;
}
.loading-app .logo span{
  font-size: 50px;
}
.loading-app .loading-text {
  text-align: center; font-size: 22px; margin-top: 40px;
}
.nk-file-name .asterisk {
  margin-top: -3px;
}
.nk-files-view-group .nk-file-name-text a{
  display: inline-block;
  width: 220px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.MuiBreadcrumbs-li a {
  color: gray;
}
.MuiBreadcrumbs-li a,
.MuiBreadcrumbs-li p {
  font-size: 20px;
}

.MuiList-padding {
  padding-bottom: 0 !important;
}

.react-pdf__message.react-pdf__message--error {
  line-height: 800px;
  text-align: center;
}

.MuiSelect-root.MuiSelect-select.MuiInput-input,
.MuiInputBase-input.MuiOutlinedInput-input.MuiAutocomplete-input,
.MuiTypography-root.MuiFormControlLabel-label,
input.MuiInputBase-input,
li.MuiListItem-root,
li.MuiMenuItem-root {
  font-size: 0.875rem;
}

.nk-file-name-text .MuiBreadcrumbs-li {
  max-width: 85%;
}

.nk-file-name-text .MuiBreadcrumbs-li a,
.nk-file-name-text .MuiBreadcrumbs-li p {
  font-size: 0.875rem;
}

.nk-file-name-text .breadcrumb-title {
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden
}

.nk-file-name-text .breadcrumb-title:hover {
  color: #6576ff;
}

.nk-file-name-text .MuiBreadcrumbs-separator {
  margin-left: unset;
  margin-right: unset;
}

.react-draggable-dragged {
  margin: 10px;
}

.react-draggable .modal-header {
  cursor: move;
}

.view-pdf .react-pdf__Page__canvas {
  border-bottom: 3px solid;
}

.nk-files-view-list .nk-file>div {
  background-color: unset;
}

.nk-files-view-list .nk-files-list .nk-file-item {
  background-color: #fff;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.nk-files-view-list .nk-files-list .nk-file-item:hover,
.nk-tb-item:not(.nk-tb-head):hover,
.nk-tb-item:not(.nk-tb-head).seleted {
  background-color: #efefef !important;
  box-shadow: 0 0 10px -4px rgba(54, 74, 99, 0.2);
}

.highlight-item {
  background-color: #efefef !important;
  box-shadow: 0 0 10px -4px rgba(54, 74, 99, 0.2);
}

.nk-sidebar-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(16,25,36,0.4);
  z-index: 600;
  animation: overlay-fade-in .4s ease 1;
}

.sc-gsTEea {
  overflow: auto;
}

.vertical-bar {
  margin-right: 10px;
  padding-right: 15px;
  border-right: 1px solid #eee;
  margin-right: 17px;
  cursor: pointer;
  font-size: 21px;
}
  
.nk-header.nk-header-fixed {
  min-height: 65px;
}

.notification-popup {
  max-height: unset !important;
}

.notification-popup .dropdown-head {
  padding: 10px 15px;
}

.notification-popup .dropdown-foot {
  padding: 7px;
}

.nk-notification {
  max-width: 400px;
  max-height: 385px;
  overflow-y: auto;
  overflow-x: hidden;
}

.nk-notification-item {
  padding: 8px 10px;
}

.nk-notification-item:hover {
  cursor: pointer;
  background-color: #efefef !important;
}

#notification-popup .dropdown-foot:hover {
  cursor: pointer;
  background-color: #efefef !important;
}

.badge-notification {
  background: #ed4f00;
  color: #fff;
  position: absolute;
  top: -8px;
  left: 10px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 20px;
  font-size: 12px;
  text-align: center;
}

.notification-dropdown .nk-quick-nav-icon:before {
  background-color: transparent;
}

.notification-dropdown .icon-status:after {
  content: unset;
}

.clearInput {
  cursor: pointer;
  margin-right: 10px;
}

.heightIcon {
  max-height: 21px;
  line-height: 21px;
}

.nk-folder-breadcrumb {
  font-size: 1.25rem !important;
}

.nk-folder-breadcrumb em.ni-caret-down-fill {
  font-size: unset !important;
}

@media screen and (max-width: 778px) {
  .appearName {
    display: block !important;
  }
}

@media screen and (max-width: 1005px) {
  .flexFooter {
    display: flex !important;
    align-items: center;
    justify-content: flex-end;
    background-color: #e5e9f2 !important;
  }

  #rePageControls {
    position: relative;
    left: 0;
    bottom: -1px;
    transform: none;
    background: '#fff';
    opacity: 0.6;
    transition: opacity ease-in-out 0.2s;
    box-shadow: 0 30px 40px 0 rgba(16, 36, 94, 0.2);
    border-radius: 4px;
  }
}

input[disabled],
button[disabled] {
  cursor: not-allowed !important;
}